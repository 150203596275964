.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  border-radius: 50%;
  border: none;
  outline: none;

  @media screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
    background-color: #e7e7e7;
  }

  @media screen and (min-width: 768px) {
    width: 20px;
    height: 20px;
    background-color: transparent;
  }

  svg {
    path {
      fill: #181818;
      transition: var(--transition);
    }
  }

  &:hover {
    color: var(--primary);

    svg {
      path {
        fill: var(--primary);
      }
    }
  }
}
