.like {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  border: none;
  outline: none;

  svg {
    path {
      transition: var(--transition);

      &:first-child {
        opacity: 0;
      }
    }
    color: var(--gray-dark);
  }

  &:hover {
    svg {
      color: var(--black);
    }
  }

  &.active {
    svg {
      path {
        fill: var(--primary);

        &:first-child {
          opacity: 1;
        }
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--primary-dark);
        }
      }

      .text {
        color: var(--primary-dark);
      }
    }
  }
}

.text {
  display: inline-flex;
  white-space: nowrap;
  transition: var(--transition);
  color: var(--gray-dark-text);

  @media screen and (max-width: 767px) {
    font-size: 0.75rem;
    line-height: 0.8125rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 0.875rem;
    line-height: 0.8125rem;
  }

  &:hover {
    color: var(--primary);
  }
}
