.button {
  position: fixed;
  bottom: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--black);
  border: 2px solid var(--white);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.14)) drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.1));
  z-index: 4;
  transition: var(--transition);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    left: 15px;
  }

  @media screen and (min-width: 768px) {
    left: 20px;
  }

  &:hover {
    background-color: var(--primary);
    filter: drop-shadow(0px 4px 8px rgba(0, 124, 194, 0.14)) drop-shadow(0px 8px 16px rgba(0, 124, 194, 0.1));
  }

  svg {
    color: var(--white);
  }
}
